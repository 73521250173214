<template>
    <main class="main">
        <template v-if="isStage('client')">
            <div class="main__head">
                <h1 class="h1">
                    Добавление нового клиента
                </h1>
            </div>
            <client
                :values="client"
                @prev="prevClient"
                @next="nextClient"
            />
        </template>
        <template v-if="isStage('verify')">
            <div class="main__head">
                <h1 class="h1">
                    Верификация клиента
                </h1>
            </div>
            <verify
                v-if="isStage('verify')"
                :values="client"
                @prev="prevVerify"
                @next="nextVerify"
            />
        </template>

        <modal
            :is-shown="!!state"
            @hide="next"
        >
            <template v-if="state === 'created_verified'">
                <h3
                    slot="header"
                    class="modal__header h2"
                >
                    Клиент добавлен
                </h3>
                <div
                    slot="content"
                    class="modal__content text"
                >
                    <p>Новый клиент успешно добавлен.</p>
                    <p>Нажмите "OK" для перехода к главному меню.</p>
                </div>
                <div
                    slot="footer"
                    class="modal__panel"
                >
                    <button
                        class="modal__submit button button--green"
                        @click="next"
                    >
                        OK
                    </button>
                </div>
            </template>
            <template v-else-if="state === 'created_unverified'">
                <h3
                    slot="header"
                    class="modal__header h2"
                >
                    Клиент добавлен
                </h3>
                <div
                    slot="content"
                    class="modal__content text"
                >
                    <p>Новый клиент успешно добавлен.</p>
                    <p>Вы можете верифицировать его телефон или пропустить этот шаг.</p>
                    <p>Нажмите "Пропустить" для перехода к главному меню.</p>
                    <p>Нажмите "Верифицировать" для перехода к верификации.</p>
                </div>
                <div
                    slot="footer"
                    class="modal__panel"
                >
                    <button
                        class="modal__prev button button--yellow"
                        @click="next"
                    >
                        Пропустить
                    </button>
                    <button
                        class="modal__next button button--green"
                        @click="state = undefined; goTo('verify');"
                    >
                        Верифицировать
                    </button>
                </div>
            </template>
            <template v-else-if="state === 'verified'">
                <h3
                    slot="header"
                    class="modal__header h2"
                >
                    Клиент верифицирован
                </h3>
                <div
                    slot="content"
                    class="modal__content text"
                >
                    <p>Клиент успешно верифицирован.</p>
                    <p>Нажмите OK для перехода к главному меню.</p>
                </div>
                <div
                    slot="footer"
                    class="modal__panel"
                >
                    <button
                        class="modal__submit button button--green"
                        @click="next"
                    >
                        OK
                    </button>
                </div>
            </template>
            <template v-else-if="state === 'unverified'">
                <h3
                    slot="header"
                    class="modal__header h2"
                >
                    Клиент не верифицирован
                </h3>
                <div
                    slot="content"
                    class="modal__content text"
                >
                    <p>Клиент не был верифицирован.</p>
                    <p>Вы можете провести верификацию позже.</p>
                    <p>Нажмите OK для перехода к главному меню.</p>
                </div>
                <div
                    slot="footer"
                    class="modal__panel"
                >
                    <button
                        class="modal__submit button button--green"
                        @click="next"
                    >
                        OK
                    </button>
                </div>
            </template>
        </modal>
    </main>
</template>

<script>
    import { clients } from '@/services';
    import client from '@/views/operations/client/main.vue';
    import verify from '@/views/operations/verify/main.vue';
    import modal from '@/components/modal.vue';

    export default {
        components: {
            client,
            verify,
            modal
        },
        data() {
            return {
                stage: 'client',
                client: undefined,
                state: undefined
            };
        },
        methods: {
            isStage(stage) {
                return this.stage === stage;
            },
            goTo(stage) {
                this.stage = stage;
            },
            prev() {
                this.$router.go(-1);
            },
            next() {
                this.$router.push({ name: 'index' });
            },
            prevClient(client) {
                this.prev();
            },
            nextClient(client) {
                this.$store.dispatch('showLoader', { label: 'Добавление клиента' });

                clients.createClient(client).then((data) => {
                    this.client = data;
                    this.state = 'created_unverified';

                    if (this.client.state.is_verified) {
                        this.state = 'created_verified';
                    }
                }).catch((error) => {
                    const err = new Error('Не удалось создать клиента');
                    err.parent = error;
                    this.$store.dispatch('showError', { err, vm: this });
                }).finally(() => {
                    this.$store.dispatch('hideLoader');
                });
            },
            prevVerify(isVerified) {
                this.client.state.is_verified = isVerified;
                this.state = isVerified ? 'verified' : 'unverified';
            },
            nextVerify(isVerified) {
                this.isVerified = isVerified;
                this.state = isVerified ? 'verified' : 'unverified';
            }
        }
    };
</script>
